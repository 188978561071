import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_rate = _resolveComponent("el-rate")
  const _component_el_table = _resolveComponent("el-table")
  const _component_el_space = _resolveComponent("el-space")
  const _component_Base = _resolveComponent("Base")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock(_component_Base, { titleIcon: "el-icon-s-claim" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_space, {
        wrap: "",
        style: {"margin-bottom":"12px","margin":"auto"},
        alignment: "start"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tabs, {
            "tab-position": "left",
            modelValue: _ctx.filter.year,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter.year = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tab_pane, {
                label: "全部",
                name: "全部"
              }),
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.yearDic, (item) => {
                return (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: item,
                  label: item,
                  name: item
                }, null, 8, ["label", "name"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _withDirectives(_createVNode(_component_el_table, { data: _ctx.fundFilter }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                label: "",
                prop: "name"
              }),
              _createVNode(_component_el_table_column, {
                label: "上报国保计划书项目数量",
                prop: "nationSetupCount"
              }),
              _createVNode(_component_el_table_column, {
                label: "批复国保计划书数量",
                prop: "nationSetupPCount"
              }),
              _createVNode(_component_el_table_column, {
                label: "上报省保计划书项目数量",
                prop: "provSetupCount"
              }),
              _createVNode(_component_el_table_column, {
                label: "批复省保计划书项目数量",
                prop: "provSetupPCount"
              }),
              _createVNode(_component_el_table_column, {
                label: "方案批复数量",
                prop: "planPCount"
              }),
              _createVNode(_component_el_table_column, {
                label: "安排国家资金项目金额",
                prop: "allocateMoneyNation"
              }),
              _createVNode(_component_el_table_column, {
                label: "申请省级资金项目金额",
                prop: "applyProvMoney"
              }),
              _createVNode(_component_el_table_column, {
                label: "安排省级资金项目金额",
                prop: "allocateMoneyProv"
              }),
              _createVNode(_component_el_table_column, {
                label: "资金执行率",
                prop: "fundPercent"
              }, {
                default: _withCtx(({ row: item }) => [
                  _createTextVNode(_toDisplayString(item.fundPercent.toFixed(2)) + " % ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "资金结余率",
                prop: "fundLeftPercent"
              }, {
                default: _withCtx(({ row: item }) => [
                  _createTextVNode(_toDisplayString(item.fundPercent > 0 ? (100 - item.fundPercent).toFixed(2) : 0) + " % ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "星级",
                width: "120"
              }, {
                default: _withCtx(({ row: item }) => [
                  _createVNode(_component_el_rate, {
                    modelValue: _ctx.star(item),
                    max: 3
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"]), [
            [_directive_loading, _ctx.isLoading]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}